import { useEffect, useRef, useState } from "react";
import { connect, ResolveThunks } from "react-redux";
import SongList from "../..";
import { noSleep } from "../../../../var";
import { rootSelectors, RootState } from "../../../../state/root_reducer";
import { actions } from "../../../../state/slices/songs";
import {
  actions as userActions,
  SongFeedbackAndSeed,
} from "../../../../state/slices/user";
import { formatDuration } from "../../../../util";
import { LoadingSpinner } from "../../../loading_spinner";
import SongActionBar from "../../../song_action_bar";
import { getIconForFeedback } from "../../../../pages/manage_likes";
import spotifyLogo from "../../../spotify_bar/spotify-logo-full.png";
import spotifyLogoSmall from "../../../spotify_bar/spotify-logo.png";

interface OwnProps {
  song: Song;
  artUri: string | null;
  artistUris: string[];
  spotifyUri: string | null;
  album: string | null;
  albumUri: string | null;
  fetchRecommendationsHandler: (
    song: Song,
    search: boolean,
    algo: "v1" | "v2"
  ) => void;
  includeScore: boolean;
}

interface StateProps {
  recommendations: SongRecommendation[];
  songFeedbackByGid: Record<string, SongFeedbackAndSeed> | null;
  isLoggedIn: boolean;
}

interface DispatchProps {
  onFetchSongFeedback: typeof userActions.fetchSongFeedback;
  onUpdateSelectedSongAndFetchArt: typeof actions.updateSelectedSongAndFetchArt;
  onAddFeedback: typeof userActions.addFeedback;
  onAddToPlaylist: typeof actions.addToPlaylist;
}

export type SongListItemDetailProps = OwnProps &
  StateProps &
  ResolveThunks<DispatchProps>;

export function SongListItemDetail(
  props: SongListItemDetailProps
): JSX.Element {
  const refreshRecsInterval = useRef<NodeJS.Timeout | null>(null);
  const [fetching, setFetching] = useState(false);
  const [algo, setAlgo] = useState<"v1" | "v2">("v2");

  useEffect(() => {
    if (!refreshRecsInterval.current && props.recommendations.length > 0) {
      startRecFetcher(algo);
    }

    const toClear = refreshRecsInterval.current;

    return () => {
      if (toClear) {
        console.log("Clearing rec fetcher: " + toClear);

        clearInterval(toClear);

        refreshRecsInterval.current = null;
      }
    };
  }, [refreshRecsInterval.current]);

  useEffect(() => {
    if (props.songFeedbackByGid === null) {
      props.onFetchSongFeedback();
    }
  }, [props.songFeedbackByGid === null]);

  useEffect(() => {
    if (props.recommendations !== null) {
      setFetching(false);
    }
  }, [props.recommendations]);

  function startRecFetcher(algo: "v1" | "v2"): void {
    refreshRecsInterval.current = setInterval(
      () => props.fetchRecommendationsHandler(props.song, false, algo),
      120_000
    );

    console.log("Started fetcher: " + refreshRecsInterval.current);
  }

  function fetchRecs(algo: "v1" | "v2"): void {
    setFetching(true);

    props.fetchRecommendationsHandler(props.song, true, algo);

    setAlgo(algo);
    startRecFetcher(algo);
  }

  function render(): JSX.Element {
    return (
      <div className="pl-2 pt-2 w-full flex flex-col">
        <div style={{ minHeight: 24 }}>
          <div className="flex flex-shrink">
            <span
              onClick={() => props.onUpdateSelectedSongAndFetchArt(null)}
              className="pb-2 text-left font-bold block text-gray-700 text-md cursor-pointer button-small"
            >
              ← Back
            </span>
          </div>
        </div>
        <div
          className="h-full flex flex-col mr-2"
          style={{ overflowY: "hidden" }}
        >
          {renderSongDetails()}
          {renderRecommendationsSection()}
        </div>
      </div>
    );
  }

  function renderSongDetails(): JSX.Element {
    const icon =
      props.songFeedbackByGid?.[props.song.gid] &&
      getIconForFeedback(props.songFeedbackByGid[props.song.gid].feedback);
    const szToClassFn = (input: string, cutoff1: number, cutoff2?: number) => {
      if (cutoff2 && input.length > cutoff2) {
        return "text-sm";
      }
      return input.length > cutoff1 ? "text-md" : "text-lg";
    };
    return (
      <div
        className="border rounded border-gray-700 flex flex-row"
        style={{ minHeight: 120 }}
      >
        {props.artUri && props.spotifyUri && (
          <a href={props.spotifyUri} target="_blank">
            <div className="p-2">
              <img
                className="rounded-sm"
                src={props.artUri}
                width="104"
                height="104"
                alt="Album Art"
              />
            </div>
          </a>
        )}
        <div className="flex flex-col flex-grow space-y-1">
          <div className="ml-2 flex flex-row mt-1 h-9">
            <a
              href={props.spotifyUri ?? "https://open.spotify.com"}
              target="_blank"
            >
              <div className="flex flex-row items-center">
                <span
                  className={`text-left block text-gray-700 font-bold ${szToClassFn(
                    props.song.name,
                    40,
                    60
                  )}`}
                >
                  {props.song.name}
                </span>
                <div className="bordered ml-2">
                  <div className="flex flex-row items-center ml-2">
                    <img
                      src={spotifyLogoSmall}
                      alt="Spotify Logo"
                      width="26"
                      height="26"
                    />
                    <p className="ml-2 mr-2">Open Spotify</p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="ml-2 flex flex-row items-center h-9">
            <span
              className={`text-left block text-gray-700 font-bold ${szToClassFn(
                props.song.artists.map((artist) => artist.name).join(", "),
                30,
                40
              )}`}
            >
              {props.song.artists.map((artist, i) => (
                <a
                  href={props.artistUris?.[i] ?? "https://open.spotify.com"}
                  target="_blank"
                >
                  {artist.name}
                  {i !== props.song.artists.length - 1 ? ", " : ""}
                </a>
              ))}
            </span>
            {props.album ? (
              <a
                className="border-l-4 border-gray-400 ml-4 pl-4"
                href={props.albumUri ?? "https://open.spotify.com"}
                target="_blank"
              >
                <span
                  className={`text-left block text-gray-700 ${szToClassFn(
                    props.album,
                    30,
                    40
                  )}`}
                >
                  {props.album}
                </span>
              </a>
            ) : null}
          </div>
          <div className="ml-2 flex flex-row items-center pb-1 h-9">
            <span className="text-left block text-gray-700 text-lg">
              {formatDuration(props.song.duration)}
            </span>
            {props.isLoggedIn ? (
              <span className="ml-4 pl-4 border-l-4 border-gray-400">
                <SongActionBar
                  addBorder={false}
                  trackId={props.song.gid}
                  hasFeedback={!!props.songFeedbackByGid?.[props.song.gid]}
                  onAddFeedback={props.onAddFeedback}
                  currentFeedback={props.songFeedbackByGid?.[props.song.gid]}
                  onPlaySong={() => {
                    noSleep.enable();
                    props.onAddToPlaylist([props.song]);
                  }}
                />
              </span>
            ) : null}
          </div>
        </div>
        {props.isLoggedIn ? (
          <div className="flex flex-row h-full">
            {props.spotifyUri ? (
              <a
                className="flex justify-center p-6 my-auto flex-col"
                href={props.spotifyUri}
                target="_blank"
              >
                <p className="text-sm mb-2">Song details powered by...</p>
                <img
                  src={spotifyLogo}
                  alt="Spotify Logo"
                  width="160"
                  height="48"
                />
              </a>
            ) : (
              <>
                <p>Song details powered by...</p>
                <img
                  className="flex justify-center p-6"
                  src={spotifyLogo}
                  alt="Spotify Logo"
                  width="160"
                  height="48"
                />
              </>
            )}
          </div>
        ) : null}
      </div>
    );
  }

  function renderRecommendationsSection(): JSX.Element {
    if (props.recommendations.length === 0 && !fetching) {
      return (
        <div>
          <button
            className="button-generic mt-2"
            onClick={() => {
              fetchRecs("v1");
            }}
          >
            Get Recs V1
          </button>
          <button
            className="button-generic mt-2 ml-2"
            onClick={() => {
              fetchRecs("v2");
            }}
          >
            Get Recs V2
          </button>
        </div>
      );
    } else {
      return fetching ? (
        <LoadingSpinner border={false} />
      ) : (
        <div className="flex flex-col pt-4" style={{ overflowY: "hidden" }}>
          <SongList
            songs={props.recommendations.map((sr) => sr.song)}
            maxSongs={500}
            otherdata={
              props.includeScore
                ? props.recommendations.map((sr) => sr.score + "")
                : undefined
            }
            otherdataName={props.includeScore ? "Score" : undefined}
            selectSongHandler={props.onUpdateSelectedSongAndFetchArt}
          />
        </div>
      );
    }
  }

  return render();
}

function mapState(state: RootState, { song }: OwnProps): StateProps {
  return {
    isLoggedIn: state.user.currentUser?.id !== undefined,
    recommendations: rootSelectors.songs.getRecommendations(state, song.gid),
    songFeedbackByGid: rootSelectors.user.getSongFeedbackByGid(state),
  };
}

const mapDispatch: DispatchProps = {
  onUpdateSelectedSongAndFetchArt: actions.updateSelectedSongAndFetchArt,
  onAddFeedback: userActions.addFeedback,
  onAddToPlaylist: actions.addToPlaylist,
  onFetchSongFeedback: userActions.fetchSongFeedback,
};

export default connect(mapState, mapDispatch)(SongListItemDetail);
