import { connect, ResolveThunks } from "react-redux";
import { actions } from "../../state/slices/user";
import { RootState } from "../../state/root_reducer";
import { useNavigate } from "react-router";
import { Terms } from "../../components/terms";

interface StateProps {}

interface DispatchProps {}

type Props = StateProps & ResolveThunks<DispatchProps>;

const TermsPage = (props: Props): JSX.Element => {
  const oddRow = "p-1 text-sm";
  const evenRow = "bg-gray-300 p-1 text-sm";
  return <Terms />;
};

export default connect()(TermsPage);
