import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import { rootSelectors, RootState } from "../../state/root_reducer";
import { actions } from "../../state/slices/user";
import { CALLBACK_ROUTE } from "../route_master/routes";

interface StateProps {
  refreshToken: string | null;
  tokenIsExpired: boolean;
  userAcceptedTerms: boolean | null;
}

export type AuthorizationProps = StateProps;

export function Authorization(props: AuthorizationProps): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoggedIn = useAppSelector((state) => state.user.accessToken !== null);

  useEffect(() => {
    if (!isLoggedIn && window.location.href.includes(CALLBACK_ROUTE)) {
      const code = new URLSearchParams(window.location.search).get("code")!;

      dispatch(actions.completeOauthFlow(code, false, navigate));
    } else if (isLoggedIn && !props.userAcceptedTerms) {
      navigate("/accept_terms");
    } else if (isLoggedIn && props.refreshToken && props.tokenIsExpired) {
      console.log(
        `Refreshing oauth token due to expiration. Using refresh token: ` +
          props.refreshToken
      );

      dispatch(actions.completeOauthFlow(props.refreshToken, true));
    }
  }, [
    isLoggedIn,
    window.location.href,
    props.tokenIsExpired,
    props.refreshToken,
  ]);

  function render(): JSX.Element {
    return <div></div>;
  }

  return render();
}

function mapState(state: RootState): StateProps {
  return {
    refreshToken: state.user.refreshToken,
    tokenIsExpired: rootSelectors.user.tokenIsExpired(state),
    userAcceptedTerms: state.user.userAcceptedTerms,
  };
}

export default connect(mapState)(Authorization);
