import { connect, ResolveThunks } from "react-redux";
import { actions } from "../../state/slices/user";
import { RootState } from "../../state/root_reducer";
import { useNavigate } from "react-router";
import { Terms } from "../../components/terms";
import { useState } from "react";

interface StateProps {}

interface DispatchProps {
  onAcceptTerms: typeof actions.acceptTerms;
}

type Props = StateProps & ResolveThunks<DispatchProps>;

const AcceptTermsPage = (props: Props): JSX.Element => {
  const oddRow = "p-1 text-sm";
  const evenRow = "bg-gray-300 p-1 text-sm";

  const [scrolled, setHasScrolled] = useState(false);

  const navigate = useNavigate();

  const onAcceptTerms = () => {
    props.onAcceptTerms(navigate);
  };

  return (
    <div className="flex flex-col">
      <div className="p-4">
        <p>
          Please read and accept the terms and conditions below to use the app:
        </p>
      </div>
      <Terms setHasScrolled={() => setHasScrolled(true)} />
      <div className="p-4">
        <button
          disabled={!scrolled}
          type="button"
          className={
            scrolled
              ? "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
              : "cursor-not-allowed w-full inline-flex justify-center rounded-md border border-black shadow-sm px-4 py-2 bg-gray-100 text-base font-medium text-black sm:ml-3 sm:w-auto sm:text-sm "
          }
          onClick={onAcceptTerms}
        >
          Accept Terms and Conditions
        </button>
      </div>
    </div>
  );
};

const mapDispatch: DispatchProps = {
  onAcceptTerms: actions.acceptTerms,
};

export default connect(null, mapDispatch)(AcceptTermsPage);
