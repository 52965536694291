export const Terms = (props: { setHasScrolled?: () => void }) => {
  const oddRow = "p-1 text-sm";
  const evenRow = "bg-gray-300 p-1 text-sm";
  return (
    <div
      onScroll={(e) => props.setHasScrolled?.()}
      className="p-4 font-bold text-gray-700 flex flex-col gap-y-2"
      style={{ overflowY: "scroll" }}
    >
      <span
        className={`block text-gray-700 font-bold text-base sm:text-lg font-sans p-1 border-b-2`}
      >
        Terms of Use
      </span>
      <p className={oddRow}>Last Updated: March 12, 2025</p>
      <p className={evenRow}>
        This End User Agreement ("Agreement") is a legally binding contract
        between you ("End User," "you," or "your") and Vybe ("Company," "we,"
        "us," or "our"), governing your use of the Spotify Platform, Spotify
        Service, and Spotify Content as made available through our application
        Vybe ("Application").
      </p>
      <span
        className={`block text-gray-700 font-bold text-base sm:text-md font-sans p-1 border-b-2`}
      >
        1. No Warranties and Disclaimers
      </span>
      <p className={evenRow}>
        You acknowledge and agree that Vybe makes no warranties or
        representations on behalf of Spotify and expressly disclaims all implied
        warranties with respect to the Spotify Platform, Spotify Service, and
        Spotify Content, including the implied warranties of merchantability,
        fitness for a particular purpose, and non-infringement.
      </p>
      <span
        className={`block text-gray-700 font-bold text-base sm:text-md font-sans p-1 border-b-2`}
      >
        2. Prohibited Actions
      </span>
      <p className={evenRow}>
        You are prohibited from: Modifying or creating derivative works based on
        the Spotify Platform, Spotify Service, or Spotify Content. Decompiling,
        reverse-engineering, disassembling, and otherwise reducing the Spotify
        Platform, Spotify Service, and Spotify Content to source code or other
        human-perceivable form, to the full extent allowed by law.
      </p>
      <span
        className={`block text-gray-700 font-bold text-base sm:text-md font-sans p-1 border-b-2`}
      >
        3. Responsibility and Liability
      </span>
      <p className={evenRow}>
        We are solely responsible for our service and disclaim any liability on
        the part of third parties, including Spotify, in connection with the use
        of the Spotify Platform, Spotify Service, and Spotify Content.
      </p>
      <span
        className={`block text-gray-700 font-bold text-base sm:text-md font-sans p-1 border-b-2`}
      >
        4. Spotify as Third Party Beneficiary
      </span>
      <p className={evenRow}>
        Spotify is a third-party beneficiary of this Agreement and is entitled
        to directly enforce the terms of this Agreement.
      </p>
      <span
        className={`block text-gray-700 font-bold text-base sm:text-md font-sans p-1 border-b-2`}
      >
        5. Agreement Modification
      </span>
      <p className={evenRow}>
        Vybe reserves the right to modify, amend, or update this Agreement at
        any time, and such modifications will be effective upon posting on our
        website or within the Application. It is your responsibility to review
        this Agreement periodically to stay informed about any updates.
      </p>
      <span
        className={`block text-gray-700 font-bold text-base sm:text-md font-sans p-1 border-b-2`}
      >
        6. Termination
      </span>
      <p className={evenRow}>
        Spotify is a third-party beneficiary of this Agreement and is entitled
        to directly enforce the terms of this Agreement.
      </p>
      <span
        className={`block text-gray-700 font-bold text-base sm:text-md font-sans p-1 border-b-2`}
      >
        7. Contact Information
      </span>
      <p className={evenRow}>
        If you have any questions or concerns about this Agreement, please
        contact us at rush8192 [at] gmail [dot] com
      </p>
      <p className={oddRow}>
        By using the Application, you agree to be bound by the terms of this
        Agreement. If you do not agree with these terms, please discontinue the
        use of the Application.
      </p>
    </div>
  );
};
