import { HOST } from "../../var";

export const CALLBACK_ROUTE = "/spotify_callback";
export const REDIRECT_URL = HOST + CALLBACK_ROUTE;

export const KNOWN_ROUTES = [
  "/songs",
  "/play",
  "/likes",
  "/debug",
  "/privacy",
  "/terms",
  "/accept_terms",
];
